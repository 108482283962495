import {HttpError, IResourceComponentsProps, useTable, useTranslate,} from "@pankod/refine-core";
import {useNavigate, useNavigation} from '../../core/customNavigation';
import {Button, DatePicker, Show, Switch, TextField, useTable as UseTablee,} from "@pankod/refine-antd";

import {IBookingRoomTimelineItem, IRoomTimelineGroup} from "interfaces";
import React, {useEffect, useRef, useState} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronLeft, faChevronRight, faCircle, faCreditCard} from '@fortawesome/free-solid-svg-icons'
import {faHackerNews} from '@fortawesome/free-brands-svg-icons'


import Timeline from 'react-vis-timeline-2'
import {TimelineOptions} from "vis-timeline/types";

import ReactDOMServer from 'react-dom/server'

const newYieldTimelineIcon = ReactDOMServer.renderToStaticMarkup(<FontAwesomeIcon fontSize={'1em'} style={{
    color: 'white',
    background: '#D2042D',
    padding: '2px',
    borderRadius: '25%'
}} icon={faHackerNews}/>)
const cardErrorTimelineIcon = ReactDOMServer.renderToStaticMarkup(<FontAwesomeIcon fontSize={'1em'} style={{
    color: 'white',
    background: '#D2042D',
    padding: '2px',
    borderRadius: '25%'
}} icon={faCreditCard}/>)
const chevronLeftIcon = <FontAwesomeIcon fontSize={'0.8em'} style={{color: 'white'}} icon={faChevronLeft}/>
const chevronRightIcon = <FontAwesomeIcon fontSize={'0.8em'} style={{color: 'white'}} icon={faChevronRight}/>
const circleIcon = <FontAwesomeIcon fontSize={'0.8em'} style={{color: 'white'}} icon={faCircle}/>


const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(
        () => {
            const {innerWidth: width, innerHeight: height} = window;
            return {width, height};
        }
    );

    return windowDimensions;
}

const newShade = (hexColor: string, magnitude: number): string => {
    hexColor = hexColor.replace(`#`, ``);
    if (hexColor.length === 6) {
        const decimalColor = parseInt(hexColor, 16);
        let r = (decimalColor >> 16) + magnitude;
        r > 255 && (r = 255);
        r < 0 && (r = 0);
        let g = (decimalColor & 0x0000ff) + magnitude;
        g > 255 && (g = 255);
        g < 0 && (g = 0);
        let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
        b > 255 && (b = 255);
        b < 0 && (b = 0);
        return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
    } else {
        return hexColor;
    }
}

const pickTextColorBasedOnBgColorAdvanced = (
    {bgColor, lightColor, darkColor}: {
        bgColor: string;
        lightColor: string;
        darkColor: string;
    }
): string => {
    var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
    var r = parseInt(color.substring(0, 2), 16); // hexToR
    var g = parseInt(color.substring(2, 4), 16); // hexToG
    var b = parseInt(color.substring(4, 6), 16); // hexToB
    var uicolors = [r / 255, g / 255, b / 255];
    var c = uicolors.map((col) => {
        if (col <= 0.03928) {
            return col / 12.92;
        }
        return Math.pow((col + 0.055) / 1.055, 2.4);
    });
    var L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2]);
    return (L > 0.250) ? darkColor : lightColor;
    return (L > 0.179) ? darkColor : lightColor; // origin
}

interface FunctProps extends IResourceComponentsProps {
    switchview(): void;
}

export const BookingRoomTimeline: React.FC<FunctProps> = (props) => {
    const t = useTranslate();
    const {show, create} = useNavigation()
    const {height, width} = useWindowDimensions();
    const navigate = useNavigate();

    const getweekstart = (now: Date = new Date()) => {
        const date = new Date();
        date.setHours(0, 0, 0);
        const diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
        return new Date(date.setDate(diff))
    }

    const [queryparam, setQueryparam] = useState({
        start: new Date((new Date(getweekstart())).setDate(getweekstart().getDate() - 1)),
        end: new Date((new Date(getweekstart())).setDate(getweekstart().getDate() + 30)),
    });

    const [timelineData, setTimelineData] = useState<{
        items: IBookingRoomTimelineItem[],
        groups: IRoomTimelineGroup[]
    }>({
        items: [],
        groups: [],
    })

    // Add timeout for avoid useless and time consuming timeline rerender
    const [resourceTimeout, setResourceTimeout] = useState('')
    setTimeout(() => {
        setResourceTimeout('bookingrooms/get_timeline');
    }, 100);

    const {sorter, tableQueryResult, setCurrent, current, pageSize} = useTable<IBookingRoomTimelineItem>({
        resource: resourceTimeout,
        initialCurrent: 1,
        initialPageSize: 10000,
        metaData: {
            date_start: (queryparam.start).toISOString().split('T')[0],
            date_end: (queryparam.end).toISOString().split('T')[0],
            status: ['valid', 'block'],
        }
    });

    const {
        tableProps: roomTableProps,
        sorter: roomSorter,
        searchFormProps: roomSearchFormProps,
        tableQueryResult: roomTableQueryResult
    } = UseTablee<IRoomTimelineGroup, HttpError, IRoomFilterVariables>({
        resource: "rooms",
        initialCurrent: 1,
        initialPageSize: 1000,
        initialSorter: [{field: "number", order: "asc"}],
    });


    const timelineRef: React.LegacyRef<Timeline> = useRef(null);

    const tlOptions: TimelineOptions = {
        xss: {
            disabled: false,

            filterOptions: {
                css: false,
                whiteList: {
                    svg: ['aria-hidden', 'focusable', 'data-prefix', 'data-icon', 'class', 'role', 'viewBox', 'xmlns', 'color', 'style'],
                    path: ['fill', 'd'],
                    div: ['style'],
                },
            },

        },
        width: '100%',
        showCurrentTime: true,
        // start: '2023-09-20',

        // end:new Date((new Date(getweekstart())).setDate(getweekstart().getDate() + 7)),
        orientation: {
            axis: 'top',
            item: 'top',
        },
        zoomKey: 'ctrlKey',
        verticalScroll: true,
        selectable: true,
        multiselect: false,
        horizontalScroll: true,
        height: `${height - 200}px`,
        locale: 'fr',
        margin: {
            item: {
                horizontal: -1
            }
        },
        template: (item, element) => {
            let cardsStr = ''
            if (item.status['wait_ypnew']) cardsStr += newYieldTimelineIcon + ' '
            if (item.status['wait_unfinish']) cardsStr += cardErrorTimelineIcon + ' '
            return '<div>' + cardsStr + (item?.status?.['block'] ? `[BLOCK] ${item.custom_request ? item.custom_request : ''}` : item.content) + '</div>';
        },
        visibleFrameTemplate: (item, element) => {
            const badgeStyle = "position:absolute;top:-8px;min-width:10px;min-height:10px;line-height:10px;padding:5px;color:transparent;font-size:10px;border-radius:20px;border:1px solid #A9A9A9;"

            const badgeWhite = (pos: string = 'right:-5px', num: number = 0) => `<div style="margin-left:${6 * num * 2}px;${pos};${badgeStyle}"></div>`
            const badgeBordeau = (pos: string = 'right:-5px', num: number = 0) => `<div style="margin-left:${6 * num * 2}px;${pos};background-color:#6d071a;${badgeStyle}"></div>`
            const badgeChartreuse = (pos: string = 'right:-5px', num: number = 0) => `<div style="margin-left:${6 * num * 2}px;${pos};background-color:chartreuse;${badgeStyle}"></div>`
            const badgeRed = (pos: string = 'right:-5px', num: number = 0) => `<div style="margin-left:${6 * num * 2}px;${pos};background-color:#bf1f1f;${badgeStyle}"></div>`

            return badgeChartreuse('left:0%') + badgeWhite('left:0%', 1) + badgeChartreuse();
        },
    }

    const addDays = (date: Date, days: number) => {
        const result = new Date(date);
        return result.setDate(result.getDate() + days);
    }

    const arrowGoto = async (days: number) => {
        if (tableQueryResult.isFetching || roomTableQueryResult.isFetching) return
        timelineRef.current?.timeline.moveTo(addDays(timelineRef.current?.timeline.getWindow().start, days))
    }

    useEffect(() => {
        if (tableQueryResult.status === 'success' && roomTableQueryResult.status === 'success') {

            const excludedRoomIds = [71, 57]; // Exclude room from timeline

            const groups = roomTableQueryResult.data.data
                .filter(room => !excludedRoomIds.includes(room.id)) // Exclure les rooms avec ces IDs
                .map(e => {
                    const darkshade = newShade(`${e.color}`, 50);
                    e.title = e.name_short;
                    e.content = e.name_short?.slice(0, 16) || '';
                    e.txtColor = pickTextColorBasedOnBgColorAdvanced({
                        bgColor: e.color,
                        lightColor: 'F7F7F7',
                        darkColor: '0B2938'
                    });
                    e.styleWaitLanding = `background:repeating-linear-gradient(45deg,${darkshade},${darkshade} 10px, #${e.color} 10px, #${e.color} 20px);`;
                    return e;
                });


            // OLD WAY OF MERGING NEW AND ALREADY FETCHED DATA
            // const itemsMergedDirty = [...timelineData.items, ...tableQueryResult.data.data]
            // const itemFitlered = itemsMergedDirty.filter((value, index, self) =>
            //   index === self.findIndex((t) => (
            //     t.id === value.id
            //   ))
            // )
            const itemFitlered = tableQueryResult.data.data

            const itemsFormated = itemFitlered.map(e => {
                const room = groups.find(g => g.id == e.room_id)
                e.group = e.room_id
                if (e.booking.client?.fancy_name == undefined) {
                    console.log(e.booking)
                }
                e.content = e.booking.client.fancy_name
                e.custom_request = e.booking.custom_request
                e.style = `background-color:#${room?.color}; color:#${room?.txtColor};`

                if (e.status.wait_landing) e.style = e.style + room?.styleWaitLanding // zebra style
                else if (e.status.block) e.style = 'background-color:white; color:black;' // block style
                e.style += 'font-size: 13px; font-weight: 400; line-height: 1.471;' // common style
                return e
            })

            setTimelineData({
                groups: groups,
                items: itemsFormated
            })

            if (pageSize < tableQueryResult.data?.total && current * pageSize < tableQueryResult.data?.total) {
                setCurrent(current + 1)
            }
        }
    }, [tableQueryResult.dataUpdatedAt, roomTableProps.loading])


    const getLoadedR = () => {
        return {start: queryparam.start, end: queryparam.end}
    }
    const rangechangeHandler = (props: { start: Date, end: Date, byUser: boolean, event: Event }) => {
        const startDt = new Date(props.start)
        const endDt = new Date(props.end)
        if (startDt < queryparam.start) {
            startDt.setDate(startDt.getDate() - 30)
            endDt.setDate(endDt.getDate() + 7)
            setQueryparam({start: startDt, end: endDt})
            setCurrent(1)
        } else if (endDt > queryparam.end) {
            startDt.setDate(startDt.getDate() - 7)
            endDt.setDate(endDt.getDate() + 30)
            setQueryparam({start: startDt, end: endDt})
            setCurrent(1)
        }
    }

    React.useEffect(() => {
        timelineRef.current?.timeline.off('rangechanged');
        timelineRef.current?.timeline.on('rangechanged', rangechangeHandler);
    }, [queryparam.start, queryparam.end]);

    useEffect(() => {
        if (timelineRef.current?.timeline.getVisibleItems().length == 0) {
            timelineRef.current?.timeline.setGroups(timelineData.groups);
        }
        timelineRef.current?.timeline.setItems(timelineData.items);
        // timelineRef.current?.timeline.setOptions(tlOptions);

    }, [timelineData.groups, timelineData.items])

    timelineRef.current?.timeline.off('select'); // FIX for function stacking
    timelineRef.current?.timeline.on('select', (selected: any) => {
            const booking_id = timelineData.items.find((e: any) => e.id == selected.items?.[0])?.booking_id
            if (booking_id != undefined)
                show("bookings", booking_id, selected?.event?.srcEvent)
        }
    );

    const tlWindow = timelineRef.current?.timeline.getWindow()
    const difference = Math.ceil(((tlWindow?.end.getTime() ?? 1) - (tlWindow?.start.getTime() ?? 0)) / (1000 * 3600 * 24));
    const date = new Date();
    date.setHours(0, 0, 0);
    const diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
    const weekstart = new Date(date.setDate(diff));
    const weekend = new Date(date.setDate(date.getDate() + 8));
    if (difference == 6 || difference > 20) {
        timelineRef.current?.timeline.setWindow(weekstart, weekend, {animation: false})
    }

    return (
        <Show
            title={`${t("menu.bookings")}`}
            isLoading={(tableQueryResult.isFetching || roomTableQueryResult.isFetching)}
            resource="bookingrooms"
            pageHeaderProps={{
                extra: [
                    <DatePicker key="page_header_tl_extra_btn00" onChange={(e) => {
                        timelineRef.current?.timeline.moveTo(new Date(e!.format('YYYY-MM-DD')), {animation: false}, () => {
                        })
                    }}/>,
                    <Button key="page_header_tl_extra_btn01" onClick={() => arrowGoto(-26)}
                            style={{backgroundColor: "blue"}}>{chevronLeftIcon}{chevronLeftIcon}</Button>,
                    <Button key="page_header_tl_extra_btn02" onClick={() => arrowGoto(-3)}
                            style={{backgroundColor: "blue"}}>{chevronLeftIcon}</Button>,
                    <Button key="page_header_tl_extra_btn03"
                            onClick={() => timelineRef.current?.timeline.moveTo(Date.now())}
                            style={{backgroundColor: "blue"}}>{circleIcon}</Button>,
                    <Button key="page_header_tl_extra_btn04" onClick={() => arrowGoto(11)}
                            style={{backgroundColor: "blue"}}>{chevronRightIcon}</Button>,
                    <Button key="page_header_tl_extra_btn05" onClick={() => arrowGoto(34)}
                            style={{backgroundColor: "blue"}}>{chevronRightIcon}{chevronRightIcon}</Button>,
                    <TextField key="page_header_tl_extra_1" value={`${t("pages.booking.list_view")} : `}/>,
                    <Switch key="page_header_tl_extra_2" onChange={props.switchview}/>,
                    <Button key="page_header_tl_extra_3"
                            onClick={(e: any) => create("bookings", e)}>{`+ ${t("pages.booking.newbooking")}`}</Button>,
                    <Button key="page_header_tl_extra_4"
                            onClick={(e: any) => navigate(`/${t('menu.bookings')}/create?${t('block')}=true`, e)}>{`+ ${t("pages.booking.block")}`}</Button>,

                ],
                // subTitle:`${t("yieldanalysis.yieldanalysis")} ${resource?.dateref ? ' - ' + dayjs(resource.dateref).locale(t("short_lang")).format('ddd D/M') : ''} ${resource?.modified ? ` (${t('last_modified')} ` + dayjs(resource.modified).locale(t("short_lang")).format('D/M, HH:mm') + ')' : ''}`
            }}
        >
            <div className="no-scrollbar">
                <Timeline
                    ref={timelineRef}
                    options={tlOptions}
                />
            </div>
        </Show>
    );
};

interface IRoomFilterVariables {
    name: string;
}
